import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/kardex";
import {OrdenActions} from "../../../Utils/Grid/OrdenActions"

import moment from "moment";

const formatearFecha = (fecha, args) => {
    if (fecha === null)
        return "---"

    return moment.utc(fecha).format("DD/MM/YYYY");
};

const estado_update = (estado) =>{
    switch (estado) {
        case 1:
            return "Pendiente"
        case 2:
            return "Revision";
        case 3:
            return "Preparacion";
        case 4:
            return "Progreso";
        case 5:
            return "Validacion";
        case 6:
        return "Finalizado";
      default:
        return estado;
    }
}



export const TablaKardex = (props) => {
    const { page, data, generarReporteKardex,user, setTabEdit } = props;

    return (
        <div>
            <Grid
                hover
                striped
                data={data}
                className="pb-4 pt-3"
                page={page}
                pagination={true}
                onPageChange={generarReporteKardex}
            >
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    width="2%"
                    dataFormat={(cell) => <div></div>}
                />
                <TableHeaderColumn 
                    dataField="creado"
                    dataFormat={formatearFecha}
                >
                    Fecha de creación
                </TableHeaderColumn>
                
                <TableHeaderColumn 
                    dataField="fecha_validacion"
                    dataFormat={formatearFecha}
                >
                    Fecha de validación
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="mantenimiento_tipo"
                    dataFormat={(cell) => cell ? cell.nombre : " "}
                >
                    Tipo de mantenimiento
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="maquinaria"
                    dataFormat={(cell) => cell ? cell.nombre : ""}
                >
                    Maquinaria
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="creado_orden"
                    dataFormat={(cell) => cell ? (cell.nombre === null ? "---" : (cell.nombre + ' ' + cell.apellido)) : "---"}
                >
                    Creado
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="valido_orden"
                    dataFormat={(cell) => cell ? (cell.nombre === null ? "---" : (cell.nombre + ' ' + cell.apellido)) : "---"}
                >
                    Validado
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="estado"
                    dataFormat={estado_update}
                    
                >
                    Estado
                </TableHeaderColumn>

                <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={OrdenActions({ ver:"orden", user, setTabEdit})}
                    >
                        Acciones
                    </TableHeaderColumn>
            </Grid>

            <div>
                <span>Total de mantenimientos: </span>
                <span>{data.count}</span>
            </div>
        </div>
        
    );
};

const mapStateToProps = (state) => ({
    ...state.kardex,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaKardex);
