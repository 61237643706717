import React, { Fragment} from 'react';


function Card(props) {
    const { noBorder } = props;
    return (
        <Fragment>
            <div className="d-flex align-items-baseline ml-3" style={{position: "relative", borderBottom:"2px solid #359D9E" }}>
                <img src={require(`assets/img/icons/${props.icon}.png`)} alt="Usuario" className="title_img mr-2"/>
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                <h2 className="mr-2 titulo">{props.titulo}</h2>
                <span className="subtitulo">{props.subtitulo}</span>
            </div>
            <div className="mb-4">
                <div className="card-content p-0 px-3 pt-3">
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default Card;
