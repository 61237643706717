import { api } from "api";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, FieldArray } from "redux-form";
import moment from 'moment'
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

import {
    AsyncSelectFieldTable

} from "../../../Utils/renderField/renderField";

import DatePicker from "react-datepicker";

import "./mantenimientoform.css"

import renderMantimientos from "./MantenimientoTable";
import { isNull } from "lodash";

const getProcesos = (search) => {
    return api
        .get("proceso", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};


const getRequerimiento = (search) => {
    return api
        .get("requerimiento", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};



const getMaquinaria = (search) => {
    return api
        .get("maquinaria", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getFechaUltima = async (id) => {
    try {
        const response = await api.get('mantenimiento/get_ultimo', {id});
        return response;
    } catch (error) {
        return [];
    }
}


const getEncargado = (search) => {
    const params ={}
    params.search = search;
    return api
        .get('user/get_encargado', params )
        .then((data) => {
            if (data) return data;
            return [];
        })
        .catch(() => {
            return [];
        });
};
const getEncargadoCodigo = async (id) => {
    try {
        const response = await api.get('user/get_codigo_encargado', {id});
        return response;
    } catch (error) {
        return [];
    }
}


class MantenimientoForm extends Component {
    constructor(props){
        super(props)
        this.ganttRef = React.createRef(null),

        this.state = {
            fecha: null,
            proceso: null,
            proceso_solicitado:null,
            requerimiento: null,
            maquinaria: null, 
            encargado_orden: null,
            codigo_encargado_orden: '',
            descripcion: '',
            observacion_adicional: '',
            mensaje: '',
            mantenimiento_tipo: 'Preventivo',
            fechaMantenimiento: null,
            fecha_solicitud: moment(),
            fecha_final: null,
            tasks: [],

          
            
        };

        this.agregar = this.agregar.bind(this);
        this.handleTaskDelete = this.handleTaskDelete.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    handleNuevoCambioChange = (event) => {
        this.setState({ cambio: event.target.value });
    }

    handleNuevaFechaChange = (date) => {
        this.setState({fecha:date})
    }

    handleProcesoChange = (dato) => {
        this.setState({proceso:dato})
    }

    handleProcesoSolicitadoChange = (dato) => {
        this.setState({proceso_solicitado:dato})
    }


    handleMaquinariaChange = async (dato) => {
        const datan = await getFechaUltima(dato.id)

        this.setState({maquinaria:dato})
        if (datan[0] === undefined)
        {
            this.setState({fechaMantenimiento: '---'})
        }
        else{
            this.setState({fechaMantenimiento: (
                datan[0].fecha_ejecucion ?
                moment(datan[0].fecha_ejecucion).format('DD/MM/YYYY'): '---')})
        }
        
    }

    handleEncargadoChange = async (dato) => {
        const datan = await getEncargadoCodigo(dato.id)
        this.setState({encargado_orden: dato,
            codigo_encargado_orden: datan.codigo_empleado
        })
    }

    ///Inputs
    handleEquipoChange = (event) => {
        this.setState({ equipo: event.target.value });
    }

    handleRequerimientoChange = (dato) => {
        this.setState({requerimiento:dato})
    }


    handleDescripcionChange = (event) =>{
        this.setState({descripcion: event.target.value})
    }

    handleObservacionAdicionalChange = (event) =>{
        this.setState({observacion_adicional: event.target.value})
    }

    handleFechaFinalChange = (date) => {
        this.setState({fecha_final:date})
    }

    handleTaskDelete(index){
        const updatedTasks = [...this.state.tasks];
        updatedTasks.splice(index, 1);

        // Actualiza el estado con el nuevo array de tareas
        this.setState({
            tasks: updatedTasks
        });
    }

    agregar(fields) {
        const {fecha, proceso, requerimiento, maquinaria,
            encargado_orden, codigo_encargado_orden, descripcion, observacion_adicional, mensaje, mantenimiento_tipo, fechaMantenimiento,
            fecha_solicitud, fecha_final, tasks, proceso_solicitado
        } = this.state;

        
        if ([fecha, proceso, maquinaria, requerimiento, encargado_orden,
            codigo_encargado_orden, descripcion, fecha_final, fecha_solicitud, proceso_solicitado].includes('')) {
                this.setState({mensaje:'Todos los campos son obligatorios'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
            return
        }

        if(isNull(maquinaria)){
            this.setState({mensaje:'Todos los campos son obligatorios'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
            return
        }

        if(isNull(proceso)){
            this.setState({mensaje:'Todos los campos son obligatorios'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
            return
        }

        if(isNull(requerimiento)){
            this.setState({mensaje:'Todos los campos son obligatorios'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
            return
        }

        if(isNull(proceso_solicitado)){
            this.setState({mensaje:'Todos los campos son obligatorios'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
            return
        }

        if (fechaMantenimiento !== "---"){
            const dateString  = fechaMantenimiento;
        
            const parts = dateString.split('/'); // Dividir la cadena en partes usando '/'
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]} 00:00 `; 
    
            const nueva_fecha = new Date(formattedDate)
            
            let fecha_creacion = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const fecha_creacion_date = new Date(fecha_creacion)

    
            if (nueva_fecha > fecha) {
                this.setState({mensaje:'Ingrese una fecha mayor al ultimo mantenimiento'})
    
                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
                return
            }


            if(fecha_creacion_date > fecha) { 
                this.setState({mensaje:'Ingrese una fecha mayor al día de hoy'})
    
                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
                return
            }

            if(fecha > fecha_final)
            {
                this.setState({mensaje:'La fecha inicio debe ser menor a la fecha final'})

                setTimeout(() => {
                    this.setState({mensaje:''})
                }, 3000);
                return
            }
        }

        fields.push({fecha:fecha, maquinaria: maquinaria.nombre, mantenimiento_tipo, requerimiento:requerimiento.id,
            encargado_orden:encargado_orden, codigo_encargado_orden:codigo_encargado_orden,
            proceso:proceso,
            maquinaria_id:maquinaria.id,
            observacion_adicional,
            descripcion,
            fecha_final,
            fecha_solicitud,
            proceso_solicitado:proceso_solicitado,
        })

        const fecha_fin = fecha_final.format("YYYY-MM-DD")



        const newTask = {
            id: fields.length,
            name: maquinaria.nombre+""+proceso.nombre,
            start: new Date(fecha),
            end: new Date(`${fecha_fin}T23:59:59`),
            type: mantenimiento_tipo,
            progress: 0,

            isDisable: true,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
            ...tasks

        }

        this.setState(prevState => ({
            tasks: [...prevState.tasks, newTask]
        }))



        this.setState({
            maquinaria: '',
            fecha: null,
            encargado_orden:'',
            proceso: null,
            maquinaria: null,
            encargado_orden:'',
            codigo_encargado_orden: '',
            descripcion:'',
            observacion_adicional: '',
            requerimiento:null,
            fechaMantenimiento: null,
            fecha_final: null,
            fecha_solicitud: moment(),
            proceso_solicitado:null,
        })
    };


    exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Gantt Tasks');
    
        // Escribe los encabezados
        worksheet.addRow(['Task', 'Start Date', 'End Date']);
    
        // Escribe los datos de las tareas
        this.state.tasks.forEach(task => {
            worksheet.addRow([task.name, task.start, task.end]);
        });
    
        // Genera el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'gantt_tasks.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevState.tasks !== this.state.tasks){
            this.props.obtener_gantt_data(this.state.tasks)

            if(this.state.tasks.length >0){
                this.forceUpdate();
            }
        }
    }

  



    render(){
        const { handleSubmit, permisos, editando, obtener_gantt_data, loader} = this.props;

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                        <div className="row col-md-12 p-0">
                            <div className="col-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_solicitud" className="m-0">
                                        Fecha creación
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="fecha_solicitud"
                                        component={({ input }) => (
                                        <DatePicker
                                            placeholder="DD/MM/AAAA"
                                            selected={this.state.fecha_solicitud}
                                            onChange={(date) => {
                                                input.onChange(date);
                                                this.handleNuevaFechaChange(date);
                                            }}
                                            disabled
                                        />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha" className="m-0">
                                        Fecha inicio
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="fecha"
                                        component={({ input }) => (
                                            <DatePicker
                                                placeholder="DD/MM/AAAA"
                                                selected={this.state.fecha}
                                                onChange={(date) => {
                                                    input.onChange(date);
                                                    this.handleNuevaFechaChange(date);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_final" className="m-0">
                                        Fecha final
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="fecha_final"
                                        component={({ input }) => (
                                        <DatePicker
                                            placeholder="DD/MM/AAAA"
                                            selected={this.state.fecha_final}
                                            onChange={(date) => {
                                                input.onChange(date);
                                                this.handleFechaFinalChange(date);
                                            }}
                                        />
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="proceso" className="m-0">
                                        Proceso solicitante
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        valueKey="id"
                                        name="proceso"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectFieldTable}
                                        loadOptions={getProcesos}
                                        className="form-control"
                                        valor={this.state.proceso}
                                        value={this.state.proceso}
                                        handleChange={this.handleProcesoChange}
                                    
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="proceso_solicitado" className="m-0">
                                        Proceso solicitado
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        valueKey="id"
                                        name="proceso_solicitado"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectFieldTable}
                                        loadOptions={getProcesos}
                                        className="form-control"
                                        valor={this.state.proceso_solicitado}
                                        value={this.state.proceso_solicitado}
                                        handleChange={this.handleProcesoSolicitadoChange}
                                    
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="maquinaria" className="m-0">
                                        Maquinaria - equipo
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        valueKey="id"
                                        name="maquinaria"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectFieldTable}
                                        loadOptions={getMaquinaria}
                                        className="form-control"
                                        valor={this.state.maquinaria}
                                        value={this.state.maquinaria}
                                        handleChange={this.handleMaquinariaChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row col-md-12 p-0">
                            <div className="col-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="requerimiento" className="m-0">
                                        Requerimiento
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        valueKey="id"
                                        name="requerimiento"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectFieldTable}
                                        loadOptions={getRequerimiento}
                                        className="form-control"
                                        valor={this.state.requerimiento}
                                        value={this.state.requerimiento}
                                        handleChange={this.handleRequerimientoChange}
                                    
                                    />
                                </div>
                            </div>

                            

                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="encargado" className="m-0">
                                        Encargado de orden
                                    </label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="encargado_orden"
                                        labelKey="nombre_completo"
                                        label="nombre_completo"
                                        component={AsyncSelectFieldTable}
                                        loadOptions={getEncargado}
                                        className="form-control"
                                        valor={this.state.encargado_orden}
                                        value={this.state.encargado_orden}
                                        handleChange = {this.handleEncargadoChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="codigo_encargado_orden" className="m-0">
                                        Código de encargado
                                    </label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <input
                                        name="codigo_encargado_orden"
                                        type="text"
                                        className="form-control"
                                        placeholder="escriba aqui"
                                        value={this.state.codigo_encargado_orden ? this.state.codigo_encargado_orden : ""}
                                        disabled

                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row col-md-12 p-0">
                            <div className="col-4 mt-3">
                                <label htmlFor="descripcion">Descripcion del mantenimiento</label>
                                <textarea name="descripcion"
                                    className="form-control"
                                    onChange={this.handleDescripcionChange}
                                    value={this.state.descripcion}
                                    placeholder="escriba aqui" rows={4} />
                            </div>

                            <div className="col-4 mt-3">
                                <label htmlFor="observacion_adicional">Observaciones adicionales</label>
                                <textarea name="observacion_adicional" 
                                    className="form-control"
                                    onChange={this.handleObservacionAdicionalChange}
                                    value={this.state.observacion_adicional}
                                    placeholder="escriba aqui" rows={4} />
                            </div>
                        </div>

                        <div className="row col-12 p-0">
                            <div className="col-6">
                                <span className="">Ultimo mantenimiento: </span>
                                <span>{this.state.fechaMantenimiento ? this.state.fechaMantenimiento : "---"}</span>
                            </div>
                        </div>

                        <div className="col-md-12 p-0 mt-4">
                            <div className="col-md-12 p-0 mt-3">
                                <div className="col-md-12 p-0">
                                    <FieldArray
                                        name="programacion_mantenimiento"
                                        component={renderMantimientos}
                                        agregar={this.agregar}
                                        mensaje={this.state.mensaje}
                                        handleTaskDelete={this.handleTaskDelete}
                                    />
                                </div>
                            </div> 
                        </div>

                        <div className="buttons-box mt-5">
                            <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                                CANCELAR
                            </Link>
                            {editando ? (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={permisos()}
                                >
                                    GUARDAR
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-primary" disabled={loader}>
                                    GUARDAR
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            
                {this.state.tasks.length > 0 &&(
                    <div>
                        <button type="button " className="btn btn-primary m-2" onClick={async () => {
                            try {
                                await this.props.obtener_gantt_excel();
                            } catch (error) {
                                console.error('Error exporting data:', error);
                            }
                        }}>
                            Descargar Excel
                        </button>

                        <button type="button " className="btn btn-primary m-2" onClick={async () => {
                            try {
                                await this.props.obtener_gantt_pdf();
                            } catch (error) {
                                console.error('Error exporting data:', error);
                            }
                        }}>
                            Descargar pdf
                        </button>

                        <div ref={this.ganttRef}>
                            <Gantt
                                tasks={this.state.tasks}
                                locale="es"
                            />
                        </div>
                    </div>
                )}

            </div>
        );
    }
    
};
MantenimientoForm = reduxForm({
    form: "MantenimientoForm",
})(MantenimientoForm);

export default MantenimientoForm;
