import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/requerimientos/requerimientos';
import RequerimientosList from './RequerimientosList';

const ms2p = (state) => {
  return {
    ...state.requerimientos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RequerimientosList);
