import React from "react";
import Select, { Creatable, Async } from "react-select";
import AsyncSelect from "react-select/lib/Async";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Switch from "react-switch";
import DayPicker from "../DayPicker";
import FileUploader from "../FileUploader/FileUploader";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";

import TimePicker from 'react-time-picker';


export const renderField = ({
    input,
    placeholder,
    type,
    maxLength,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                maxLength={maxLength}
                disabled={disabled}
                
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};


export const renderFieldLogin = ({
    input,
    placeholder,
    type,
    maxLength,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                maxLength={maxLength}
                disabled={disabled}
                
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};


export const renderTextArea = ({
    input,
    placeholder,
    rows,
    meta: { touched, error },
    disabled,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: "none" }}
                rows={rows || 3}
                disabled={disabled}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCurrency = ({
    input,
    meta: { touched, error },
    prefix = "Q ",
    placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitch = ({
    input,
    meta: { touched, error },
    label,
    disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldCheck = ({
    input,
    label,
    value,
    disabled,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderFieldRadio = ({
    input,
    label,
    value,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const SelectField = (
    {
        input,
        isMulti,
        options,
        disabled,
        onCambio,
        objAsValue=false,   // Si es true, a input.onChange() se pasa el item y no item[valueKey]
        onCambioObj,
        placeholder,
        isClearable,
        isSearchable,
        is_departamentos,
        labelKey="label",
        valueKey="value",
        meta: { touched, error, dirty }
    }) => {

    const invalid = Boolean((dirty && error) || (touched && error));
    const _options = [];
    if (is_departamentos){
        {Object.keys(options).forEach((option, index) => {
            _options.push({...option, label: options[option], value: option})
        })}
    } else {
        options.forEach(option => {
            _options.push({...option, label: option[labelKey], value: option[valueKey]});
        });
    }
    let value = input.value;
    if (value !== null && value !== undefined) {
        if ((typeof value) === 'object') {
            value = _.find(_options, {value: value[valueKey]});
        } else {
            value = _.find(_options, {value});
        }
    }
    if (value === undefined){
        value = null
    }
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={"Seleccione..."}
                onChange={(e) => {
                    input.onChange(e ? (objAsValue ? e : e[valueKey]) : null);
                    if(!!onCambio){
                        onCambio(e ? e['id'] : null)
                    }
                    !!onCambioObj && onCambioObj(e);
                }}
                // onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    valueKey,
    labelKey,
    onCambio,
    valor,
    isMulti,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncSelect
                isClearable={isClearable}
                cacheOptions
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : "Seleccione..."}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (!!onCambio) {
                        onCambio(e ? e : null);
                    }
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => option[valueKey]}
                getOptionLabel={(option) => option[labelKey]}
                isMulti={isMulti}
            />
            {invalid && <div className="invalid-force">{error}</div>}
        </React.Fragment>
    );
};



///// 
export const AsyncSelectFieldTable = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    valueKey,
    labelKey,
    onCambio,
    valor,
    isMulti,
    handleChange,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncSelect
                isClearable={isClearable}
                cacheOptions
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : "Seleccione..."}
                onChange={(e) => {
                    valor=e ? e: null;
                    handleChange(valor)
                    input.onChange(valor ? valor : null);
                    if (!!onCambio) {
                        onCambio(e ? e : null);
                    }
                }}
                value={valor}
                isDisabled={disabled}
                getOptionValue={(option) => option[valueKey]}
                getOptionLabel={(option) => option[labelKey]}
                isMulti={isMulti}
            />
            {invalid && <div className="invalid-force">{error}</div>}
        </React.Fragment>
    );
};






export const CreatableSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = "label",
    valueKey = "value",
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDayPicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(e) => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDatePicker = ({
    className,
    disabled,
    onCambio,
    maxDate,
    minDate,
    todayButton,
    addClass,
    dateFormat,
    placeholder,
    showPopperArrow,
    fecha_inicial,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e);
                    }
                }}
                // selected={input.value ? moment(input.value) : fecha_inicial}
                selected={input.value ? moment.utc(input.value) : fecha_inicial}



                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames(
                    "form-control",
                    { "is-invalid": invalid },
                    addClass
                )}
            />
            {invalid && <div className="invalid-force">{error}</div>}
        </div>
    );
};

export const renderFieldDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                style={{border:"0", textAlign:"center"}}
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid }
                    
                )}
                disabled
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};



export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const valueIsValid = (value) => {
        if (value instanceof Date) {
            return true;
        }
        return value && value.length === 5 && value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    };
    const invalid = touched && error;
    return (
        <div className={classNames( { 'is-invalid': invalid })}>
            <TimePicker
                maxDetail="minute"
                locale="en-US"
                disableClock={ true }
                clearIcon={ null }
                name={ name }
                value={ input.value }
                onChange={(value) => {
                    if (valueIsValid(value)) {
                        input.onChange(value);
                    }
                }}
                disabled={disabled}/>
            {invalid && <div className="invalid-force" >
                {error}
            </div>}
        </div>
    )
};


export const renderDatePickerDisabled = ({
    className,
    disabled,
    onCambio,
    maxDate,
    minDate,
    todayButton,
    addClass,
    dateFormat,
    placeholder,
    showPopperArrow,
    fecha_inicial,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className="form-control-disabled text-center">
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY HH:mm A"}
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e);
                    }
                }}
                selected={input.value ? moment(input.value) : fecha_inicial}
                
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid },
                    addClass
                )}
            />
            {invalid && <div className="invalid-force">{error}</div>}
        </div>
    );
};



export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderFieldDisabled,
    renderTimeField,
    renderDatePickerDisabled,
    AsyncSelectFieldTable,
    renderFieldLogin,
};