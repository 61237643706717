export const generos = [
    {value: 10, label: "Hombre"},
    {value: 20, label: "Mujer"}
];



export const unidades_medida = [
    {value: "Litro", label: "Litro"},
    {value: "Unidad", label: "Unidad"},
];



export const roles = [
    {value: 10, label: "Administrador"},
    {value: 20, label: "Laboratorista"},
    {value: 30, label: "Encargado de Compras"},
    {value: 40, label: "Encargado de liquidaciones"},
];

// Tamaño de paginación definido en la API
export const PAGINATION_SIZE_PER_PAGE = 10;

export const meses = [
    {value: 1, label: "Enero"},
    {value: 2, label: "Febrero"},
    {value: 3, label: "Marzo"},
    {value: 4, label: "Abril"},
    {value: 5, label: "Mayo"},
    {value: 6, label: "Junio"},
    {value: 7, label: "Julio"},
    {value: 8, label: "Agosto"},
    {value: 9, label: "Septiembre"},
    {value: 10, label: "Octubre"},
    {value: 11, label: "Noviembre"},
    {value: 12, label: "Diciembre"},
];

// export const ANIO_INICIAL = 2020;
export const ANIO_INICIAL = 2015;

// Estado de ordenes de trabajo

export const estado_orden = [
    {value: 1, label: "Pendiente"},
    {value: 2, label: "Revisión"},
    {value: 3, label: "Preparación"},
    {value: 4, label: "Progreso"},
    {value: 5, label: "Validación"},
    {value: 6, label: "Finalizado"},
]