import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/procesos/procesos';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import ProcesosCrear from './ProcesosCrear';

const mstp = state => {
    return {...state.procesos}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(ProcesosCrear)
