import { api } from "api";

import React, { Fragment, Component} from "react";
import moment from "moment";


import OrdenTab from "../Crear/OrdenTab";
import {getMe} from "../../../../../redux/modules/cuenta/login";
import CardForm from 'Utils/Cards/CardForm';
import Form from './OrdenesForm';
import OrdenesForm from './OrdenesForm';
import RevisionForm from "./RevisionForm"
import PreparacionForm from './PreparacionForm';
import ProgresoForm from './ProgresoForm';
import ValidacionForm from './ValidacionForm';

export default class OrdenDetalle extends Component {
    // constructor(props){
    //     super(props);
    // }

    state = {
        editar: false,
        estado: "",
        id_orden:"",
        submit_estado: false
    };

    componentDidMount(){
        if (this.props.match.params.estado === "pendiente"){
            this.props.leer(this.props.match.params.id)
        }
        else{
            this.props.listar_preparacion(this.props.match.params.id, this.props.match.params.estado);
        }

        this.setState({
            estado: this.props.match.params.estado == "finalizado" ? "pendiente": this.props.match.params.estado,
            id_orden: this.props.match.params.id
        })

    }

    prerender(updateData, crear){
        let estado = this.props.tab_create

        if (estado == "")
            estado = this.state.estado
                
        if(estado=="pendiente"){
            return(
                <OrdenesForm history={this.props.history}/>
            )
        }

        if(estado=="revision"){
            return(
                <RevisionForm
                    onSubmit={this.actualizar_revision} 
                    history={this.props.history}
                />
            )
        }

        if(estado=="preparacion"){
            return(
                <PreparacionForm
                    onSubmit={this.actualizar_revision}
                    history={this.props.history}
                />
            )
        }

        if(estado=="progreso"){
            return(
                <ProgresoForm
                    updateData={updateData}
                    history={this.props.history}
                />
            )
        }

        if(estado=="validacion"){
            return(
                <ValidacionForm
                    updateData={updateData}
                    history={this.props.history}
                />
            )
        }

        return (<Form history={this.props.history}/>)
    }

    render(){
        const { item } = this.props


        const { crear, tab, setTab , setTabEdit, tab_create} = this.props;
        const { data, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm 
                    icon="ordentrabajomenu"
                    titulo="Ordenes de trabajo"
                    subtitulo={`ID: ${this.state.id_orden}`}
                    col="12"
                >
                    <OrdenTab 
                        tab={tab}
                        setTab={setTab}
                        setTabEdit={setTabEdit}
                        state={this.state}
                        tab_create={tab_create}
                        estadoDetalle = {this.state.estado}
                />
                   {this.prerender(data,crear)}
                </CardForm>
            </div>
        );
    }
}

