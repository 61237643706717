import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { actions } from "../../../../../redux/modules/kardex";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";


import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';

import { api } from 'api';
let listaTipoMantenimientos=[], listaMaquinarias=[]
const getMantenimientoTipos = (search) => {
    return api.get('mantenimiento_tipo', {search})
        .then((data) => {listaTipoMantenimientos = data ? data.results : []; return listaTipoMantenimientos})
        .catch(() => []);
};

const getMaquinarias = (search) => {
    return api.get("maquinaria", { search })
        .then((data) => { listaMaquinarias = data ? data.results: []; return listaMaquinarias})
        .catch(() => []);
};


const getMantenimientoTipoByID = (idMantenimientoTipo) => {
    let mantenimientoTipoSelec = null;
    listaTipoMantenimientos.forEach(mantenimientoTipo => mantenimientoTipo.id === idMantenimientoTipo && (mantenimientoTipoSelec = mantenimientoTipo));
    return mantenimientoTipoSelec;
};

const getMaquinariaByID = (idMaquinaria) => {
    let maquinariaSelec = null;
    listaMaquinarias.forEach(maquinaria => maquinaria.id === idMaquinaria && (maquinariaSelec = maquinaria));
    return maquinariaSelec;
};



export const Filtros = (props) => {

    const {
        generarReporteKardex,
        setSelectFechaFinal,
        setSelectFechaInicial,
        select_fecha_final,
        select_fecha_inicial,
        mantenimiento_tipo_id, 
        filtroMantenimientoTipoChange,
        maquinaria_id,
        filtroMaquinariaChange,
        loader,
        obtener_kardex_excel

    } = props;

    const {
        formState: { errors },
        control,
    } = useForm();


    return (
        
        <div className="row mb-4 ml-1 align-items-center">
            <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                <div className="col-12 col-lg-12 p-0">
                    <label className="my-1 mr-2 pl-0">Tipo de mantenimiento</label>
                </div>
                <div className="col-12 col-lg-12 p-0">
                    <Controller
                        name="mantenimiento_tipo"
                        control={control}
                        defaultValue={maquinaria_id}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (

                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Seleccionar"
                                cacheOptions
                                defaultOptions
                                loadOptions={getMantenimientoTipos}
                                onChange={(e) => {
                                    filtroMantenimientoTipoChange(e ? e.id : -1);
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                value={getMantenimientoTipoByID(mantenimiento_tipo_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-6 my-2" style={{borderRadius: '1em'}}>
                <div className="col-3 col-lg-12 p-0">
                    <label className="my-1 mr-2 pl-0">Maquinaria</label>
                </div>
                <div className="col-12 col-lg-12 p-0">
                    <Controller
                        name="maquinaria"
                        control={control}
                        defaultValue={maquinaria_id}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Seleccionar"
                                cacheOptions
                                defaultOptions
                                loadOptions={getMaquinarias}
                                onChange={(e) => {
                                    filtroMaquinariaChange(e ? e.id : -1);
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                value={getMaquinariaByID(maquinaria_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        )}
                    />
                </div>
                
            </div>
            
            <div className="col-xl-2 col-lg-3 col-md-6 my-2">
                <div className="col-3 col-lg-12 p-0">
                    <label htmlFor="fecha_inicial">Fecha Inicial</label>
                </div>
                <div className="col-12 col-lg-12 p-0">
                    <Controller
                        name="fecha_inicial"
                        control={control}
                        defaultValue={select_fecha_inicial}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha de Inicial"
                                onChange={(value) => {
                                    setSelectFechaInicial(value);
                                    onChange(value);
                                }}
                                onBlur={onBlur}
                                // value={select_fecha_inicial}
                                selected={select_fecha_inicial}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es requerido",
                            },
                        }}
                    />
                
                </div>
                <div className="col-12 col-lg-12 p-0">
                    <ErrorMessage
                        errors={errors}
                        name="fecha_inicial"
                        render={({ message }) => (
                            <p className="text-danger p-0 m-0 pl-2">
                                {message}
                            </p>
                        )}
                    />
                </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-6 my-2 form-group">
                <div className="col-3 col-lg-12 p-0">
                    <label htmlFor="fecha_final">Fecha Final</label>
                </div>
                <div className="col-12 col-lg-12 p-0">
                    <Controller
                        name="fecha_final"
                        control={control}
                        defaultValue={select_fecha_final}
                        render={({
                            field: { onChange, onBlur, value },
                        }) => (
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha de Final"
                                onChange={(value) => {
                                    setSelectFechaFinal(value);
                                    onChange(value);
                                }}
                                onBlur={onBlur}
                                // value={select_fecha_final}
                                selected={select_fecha_final}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es requerido",
                            },
                        }}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="fecha_final"
                        render={({ message }) => (
                            <p className="text-danger ">
                                {message}
                            </p>
                        )}
                    />
                </div>
            </div>

            <div className="col-xl-4 my-2 p-0 d-flex">
                <div className='col-lg-12 mt-4 ml-2'>
                    <button
                        type="submit"
                        className="btn btn-primary mr-2"
                        onClick={async () => {
                            try {
                                await generarReporteKardex();
                            } catch (error) {
                                console.error('Error generating report:', error);
                            }
                        }}
                    >
                        Buscar
                    </button>

                    <button type="button" className="btn" onClick={async () => {
                        try {
                            await obtener_kardex_excel();
                        } catch (error) {
                            console.error('Error exporting data:', error);
                        }
                    }} disabled={loader}>Descargar</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.kardex,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
