import { connect } from 'react-redux';
// import { actions } from '../../../../../redux/modules/mantenimientos/mantenimientos';
// import { actions } from '../../../../../redux/modules/mantenimientos/ordenes';
import { actions } from '../../../../../redux/modules/mantenimientos/mantenimientos'
import {getMe} from "../../../../../redux/modules/cuenta/login";
import MantenimientoCrear from './MantenimientosCrear';

const mstp = state => {
    return {...state.mantenimientos}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(MantenimientoCrear)
