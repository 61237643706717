import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/EquipoR/equipoR";
import { Equipo_Reporte } from "./Equipo";

const ms2p = (state) => {
    const user = state.login.me;

    return{
        ...state.equipoR,
        user
    };
}

const md2p = {...actions};

export default connect(ms2p, md2p)(Equipo_Reporte)


