

const validate = values =>{
    const errors={}
    
    if(!values.nombre){
        errors.nombre = 'Campo requerido.'
    }

    if(!values.codigo){
        errors.codigo = 'Campo requerido.'
    }

    if(!values.fecha_adquisicion){
        errors.fecha_adquisicion = 'Campo requerido.'
    }


    if (typeof values.detalle_maquinaria !== 'undefined'){
        const elementsArrayErrors = []
        values.detalle_maquinaria.forEach((element, elementIndex) => {
            const elementErrors = {}
            if(!element || !element.nombre){
                elementErrors.nombre = 'Campo requerido.'
                elementsArrayErrors[elementIndex] = elementErrors
            }

            if(!element || !element.cantidad){
                elementErrors.cantidad = 'Campo requerido.'
                elementsArrayErrors[elementIndex] = elementErrors
            }
        });


        if(elementsArrayErrors.length){
            errors.detalle_maquinaria = elementsArrayErrors
        }
    }


    return errors
}


export default validate;