import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/reportes/EquipoR/equipoR";
import {OrdenActions} from "../../../Utils/Grid/OrdenActions"
import moment from "moment";

const formatearFecha = (fecha, args) => {
    if (fecha === null)
        return "---"

    return moment.utc(fecha).format("DD/MM/YYYY");
};

const estado_update = (estado) =>{
    switch (estado) {
        case 1:
            return "pendiente"
        case 2:
            return "revision";
        case 3:
            return "preparacion";
        case 4:
            return "progreso";
        case 5:
            return "validacion";
        case 6:
        return "finalizado";
      default:
        return estado;
    }
}


export const TablaEquipo = (props) => {
    const { page, data, generarReporte, user, setTabEdit } = props;

    return (
        <div>
            <Grid
                hover
                striped
                data={data}
                className="pb-4 pt-3"
                page={page}
                pagination={true}
                onPageChange={generarReporte}
            >
                <TableHeaderColumn
                    dataField="maquinaria"
                    dataFormat={(cell) => cell ? cell.codigo : " "}
                    dataAlign="center"
                    width="15%"
                >
                    Código de maquinaria
                </TableHeaderColumn>

                <TableHeaderColumn 
                    dataField="maquinaria"
                    dataFormat={(cell) => cell ? cell.nombre : " "}
                    dataAlign="center"
                    width="20%"

                >
                    Maquinaria
                </TableHeaderColumn>
                
                <TableHeaderColumn 
                    dataField="id"
                    isKey
                    dataAlign="center"
                    width="15%"
                    
                >
                    Código de orden de trabajo
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="creado"
                    dataFormat={formatearFecha}
                    dataAlign="center"
                    width="15%"
                >
                    Fecha de solicitud
                </TableHeaderColumn>


                <TableHeaderColumn 
                    dataField="mantenimiento_tipo"
                    dataFormat={(cell) => cell ? cell.nombre : " "}
                    dataAlign="center"
                    width="20%"
                    
                >
                    Tipo de mantenimiento
                </TableHeaderColumn>
                
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={OrdenActions({ ver:"equipo", user, setTabEdit})}
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </div>
        
    );
};

const mapStateToProps = (state) => ({
    ...state.equipoR,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaEquipo);
