import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import roles from './modules/roles/roles';
import procesos from './modules/procesos/procesos';
import maquinarias from './modules/maquinarias/maquinarias';
import ordenes from './modules/ordenes/ordenes';
import mantenimientos from './modules/mantenimientos/mantenimientos';
import kardex from './modules/kardex';
import {mantenimientoR, instalacionR, equipoR} from './modules/reportes';
import configuracion from './modules/configuracion/configuracion';
// import mantenimientoR from './modules/reportes/mantenimientoR/mantenimientoR';
// import instalacionR from './modules/reportes/InstalacionR/instalacionR';
// import equipoR from './modules/reportes/EquipoR/equipoR';
import medidas from './modules/medidas/medidas';
import requerimientos from './modules/requerimientos/requerimientos';

import notificaciones from './modules/notificaciones/notificaciones';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    roles,
    procesos,
    maquinarias,
    ordenes,
    mantenimientos,
    kardex, 
    mantenimientoR,
    instalacionR,
    equipoR,
    configuracion,
    medidas,
    requerimientos
});
