import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/ordenes/ordenes';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import OrdenesList from './Ordenes';


const ms2p = (state) => {
  const user = state.login.me;
  return {
    ...state.ordenes,
    user
  };
};

const md2p = { ...actions, getMe };

export default connect(ms2p, md2p)(OrdenesList);
