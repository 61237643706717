import { api } from "api";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm } from "redux-form";
import {
    renderTextArea,
} from "../../../Utils/renderField/renderField";

import moment from "moment";

// Formulario validacion
import {BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";


let ValidacionForm = (props) => {
    const { handleSubmit, updateData, loader, editando, submit_estado } = props;

    const options = {
        noDataText: loader ? 'Cargando...' : 'No hay datos'
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label>
                    Materiales solicitados
                </label>
                <BootstrapTable 
                    data={loader ? [] : updateData.orden_material} 
                    options={options}
                >
                    <TableHeaderColumn isKey hidden 
                        dataField="id"
                        dataAlign="center"
                    >
                        Id
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        dataField="nombre"
                        width="50%"
                        dataAlign="center"
                    >
                        Material
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                        dataField="medida"
                        width="50%"
                        dataAlign="center"
                        dataFormat={cell => cell ? cell.nombre : '---' }

                    >
                        Medida
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                        dataField="cantidad"
                        width="50%"
                        
                        dataAlign="center"
                    >
                        Cantidad
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>

            <div className="mb-4">
                <label>
                    Bitacora de cambios
                </label>
                <BootstrapTable 
                    data={loader ? [] : updateData.orden_bitacora_cambio} 
                    options={options} 
                >
                    <TableHeaderColumn isKey hidden 
                        dataField="id"
                        dataAlign="center"
                    >
                        Id
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                        dataField="fecha"
                        width="50%"
                        dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY HH:mm A') : '---' }
                        dataAlign="center"
                    >
                        Fecha y hora
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                        dataField="nombre"
                        width="50%"
                        dataAlign="center"
                    >
                        cambios realizados
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>

            <div className="col-md-12 p-0">
                <div className="col-md-12 col-sm-12">
                    <label htmlFor="observacion_diagnostico" className="m-0">
                        Observaciones finales
                    </label>
                </div>
                <div className="col-md-12 col-sm-12">
                    <Field name="observacion_diagnostico" component={renderTextArea} placeholder="Escriba aqui" rows={4} disabled={submit_estado ? submit_estado : false }/>

                </div>
            </div>

            <div className="buttons-box mt-5">
                <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                    CANCELAR
                </Link>
                {editando ? (
                    <button
                        type="submit"
                        className="btn btn-primary"
                        style={{display: submit_estado ? "none" : ""}}
                    >
                        GUARDAR
                    </button>
                ) : (
                    <button type="submit" className="btn btn-primary">
                        GUARDAR
                    </button>
                )}
            </div>
        </form>
    );
}


ValidacionForm = reduxForm({
    form: "OrdenForm",
})(ValidacionForm);

export default ValidacionForm;