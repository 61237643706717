import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {OrdenActions} from "../../../Utils/Grid/OrdenActions"
import {OrdenDias} from "../../../Utils/Grid/OrdenDias";
import moment from "moment";

import {formatActivoOrden, MantenimientoProgramado} from "../../../Utils/renderField/renderReadField";


export default class OrdenList extends Component{
    async componentDidMount() {
        const { listar, page_finalizadas, filtroProcesoChange,user } = this.props;
        await this.cargarDatos(listar, page_finalizadas, filtroProcesoChange,user);
    }

    async cargarDatos(listar, page_finalizadas, filtroProcesoChange,user) {
        const procesoId = user.proceso.id
        const procesoNombre = user.proceso.nombre
        
        const proceso = {
            id:procesoId,
            nombre: procesoNombre,
        }

        const nombre_rol = user.rol_usuario ? user.rol_usuario.nombre_rol: "Administrador"

        if(nombre_rol === "Administrador")
            await filtroProcesoChange(proceso);
        
        await listar(page_finalizadas);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar, updateEstado,setTab, setTabEdit } = this.props;
        const { data, loader, page, user } = this.props;
       
        return(
            <div className="mb-4 col-12">
                <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange}>
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataSort
                        width='7%'
                    >
                        ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="encargado_orden"
                        dataFormat={(cell) => cell ? cell.nombre + " " + cell.apellido : "---"}
                        dataSort
                        width='15%'
                    >
                        Encargado
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="maquinaria"
                        dataFormat={(cell) => cell ? cell.nombre : " "}
                        dataSort
                        width='20%'
                    >
                        Maquinaria
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="mantenimiento_tipo"
                        dataSort
                        dataFormat={(cell) => cell ? cell.nombre : " "}
                        width='20%'
                    >
                        Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado"
                        dataSort
                        dataFormat={formatActivoOrden}
                        width='20%'
                    >
                        Estado
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="proceso"
                        dataSort
                        dataFormat={(cell) => cell ? cell.nombre: "---"}
                        width='20%'
                    >
                        Proceso solicitante
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="mantenimiento_programado"
                        dataSort
                        dataFormat={MantenimientoProgramado}
                        width='20%'
                    >
                        Mantenimiento programado
                    </TableHeaderColumn>


                    <TableHeaderColumn
                        dataField="fecha_ejecucion"
                        dataSort
                        dataFormat={(cell,row) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        width='15%'
                    >
                        Fecha estimada
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        width='15%'
                        dataFormat={OrdenDias({ver:"dias"})}
                    >
                        Dias restantes
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        width='20%'
                        dataFormat={OrdenActions({ ver:"orden", editar: "orden", updateEstado,eliminar, setTabEdit, user})}
                    >
                        Acciones
                    </TableHeaderColumn>

                </Grid>
            </div>
        )
    }
}
