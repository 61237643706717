import { connect } from 'react-redux'
import { actions } from '../../../../../redux/modules/reportes/InstalacionR/instalacionR'
import Instalacion_Reporte from './Instalacion'


const ms2p= (state) => {
    const user = state.login.me;

    return{
        ...state.instalacionR,
        user
    };
}

const md2p = {...actions};

export default connect(ms2p, md2p)(Instalacion_Reporte)


