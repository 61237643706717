import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import Form from './OrdenesForm';
import OrdenesForm from './OrdenesForm';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import RevisionForm from "./RevisionForm"
import PreparacionForm from './PreparacionForm';
import ProgresoForm from './ProgresoForm';
import ValidacionForm from './ValidacionForm';
import OrdenTab from './OrdenTab';
import { api } from "api";




export default class OrdenCrear extends Component {
    state = {
        editar: false,
        estado: "",
        id_orden:"",
        submit_estado: false
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ 
                editar: true, 
                estado: this.props.match.params.estado,
                id_orden: this.props.match.params.id
            });
             
            if (this.props.match.params.estado !== "pendiente"){
                this.props.listar_preparacion(this.props.match.params.id, this.props.match.params.estado);
                return
            }

            this.props.leer(this.props.match.params.id, this.props.tab);
        }
        else{
            this.props.clearItem();
        }
    }

    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data);
    };

    actualizar_revision = (data) => {
        this.props.editar_revision(this.props.match.params.id, this.props.match.params.estado, data);
    }

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    };

   

    prerender(updateData, crear, user, loader){
        let estado = this.props.tab_create
        let submit_estado = this.state.submit_estado;

        if (estado == ""){
            estado = this.state.estado
        }

        if(estado != this.state.estado)
            submit_estado = true

        if(this.state.editar && estado=="pendiente"){
            return(
                <OrdenesForm
                    onSubmit={this.actualizar}
                    updateData={updateData}
                    editando={true}
                    permisos={this.getPermissions}
                    submit_estado={submit_estado}
                    item= {this.props.item}
                    user = {user}
                    loader={loader}
                />
            )
        }

        if(this.state.editar && estado=="revision"){
            return(
                <RevisionForm
                    onSubmit={this.actualizar_revision}
                    updateData={updateData}
                    editando={true}
                    permisos={this.getPermissions}
                    submit_estado={submit_estado}
                    loader={loader}
                />
            )
        }

        if(this.state.editar && estado=="preparacion"){
            return(
                <PreparacionForm
                    onSubmit={this.actualizar_revision}
                    updateData={updateData}
                    editando={true}
                    permisos={this.getPermissions}
                    submit_estado={submit_estado}
                    user={user}
                    loader={loader}
                />
            )
        }

        if(this.state.editar && estado=="progreso"){
            return(
                <ProgresoForm
                    onSubmit={this.actualizar_revision}
                    updateData={updateData}
                    editando={true}
                    permisos={this.getPermissions}
                    submit_estado={submit_estado}
                    {...this.props}
                    loader={loader}

                />
            )
        }

        if(this.state.editar && estado=="validacion"){
            return(
                <ValidacionForm
                    onSubmit={this.actualizar_revision}
                    updateData={updateData}
                    editando={true}
                    permisos={this.getPermissions}
                    submit_estado={submit_estado}
                    loader={loader}
                />
            )
        }

        return (<Form onSubmit={crear} user={user} loader={loader}/>)
    }
    render() {
        const { crear, tab, setTab , setTabEdit, tab_create, user} = this.props;
        const { data, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm 
                    icon="ordentrabajomenu"
                    titulo="Ordenes de trabajo"
                    subtitulo={this.state.editar ? `ID: ${this.state.id_orden}`: "Nueva orden de trabajo"}
                    col="12"
                >
                    {!this.state.editar ? " ":
                        <OrdenTab
                        tab={tab}
                        setTab={setTab}
                        setTabEdit={setTabEdit}
                        state={this.state}
                        tab_create={tab_create}
                        editar = {this.state.editar}
                        user={user}
                        />
                    }
                    {this.prerender(data, crear, user,loader)}
                </CardForm>
            </div>
        );
    }
}
