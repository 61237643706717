import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, Registro} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/ChangePassword/';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
require('../style/index.css');

//Usuarios
import {CrearUsuario, UsuariosList} from './common/components/App/Usuarios'

// Roles
import {RolesCrear, RolesListar} from "./common/components/App/Roles"
// Procesos

import { ProcesosCrear, ProcesosListar } from './common/components/App/Procesos';
import { MaquinariasCrear, MaquinariasListar } from './common/components/App/Maquinarias';
import { OrdenesCrear ,OrdenesListar, OrdenDetalle } from './common/components/App/Ordenes';

// Medidas
import {MedidasCrear, MedidasListar} from './common/components/App/Medidas';

// Reportes

import Kardex_Reporte from  './common/components/App/Reportes/Kardex'
import Mantenimiento_Reporte from './common/components/App/Reportes/Mantenimiento';
import Instalacion_Reporte from './common/components/App/Reportes/Instalacion';
import Equipo_Reporte from './common/components/App/Reportes/Equipo';
import { MantenimientosCrear } from './common/components/App/Mantenimientos';

import ConfiguracionEditar from "./common/components/App/Configuracion";
import { RequerimientosCrear, RequerimientosListar } from './common/components/App/Requerimientos';



module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                {/* Inicio de sesión */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>

                {/* General */}
                <ProtectedRoute exact path="/" component={OrdenesListar} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />

                 {/*Usuarios*/}
                <ProtectedRoute exact path="/usuarios" component={UsuariosList}/>
                <ProtectedRoute exact path="/usuario/crear" component={CrearUsuario}/>
                <ProtectedRoute exact path="/usuario/:id/editar" component={CrearUsuario}/>

                {/* Roles */}
                <ProtectedRoute exact path="/roles" component={RolesListar}/>
                <ProtectedRoute exact path="/rol/crear" component={RolesCrear}/>
                <ProtectedRoute exact path="/rol/:id/editar" component={RolesCrear}/>


                {/* Proceso */}
                <ProtectedRoute exact path="/procesos" component={ProcesosListar}/>
                <ProtectedRoute exact path="/proceso/crear" component={ProcesosCrear}/>
                <ProtectedRoute exact path="/proceso/:id/editar" component={ProcesosCrear}/>


                {/* Maquinarias */}
                <ProtectedRoute exact path="/maquinarias" component={MaquinariasListar}/>
                <ProtectedRoute exact path="/maquinaria/crear" component={MaquinariasCrear}/>
                <ProtectedRoute exact path="/maquinaria/:id/editar" component={MaquinariasCrear}/>

                {/* Ordenes */}

                <ProtectedRoute exact path="/ordenes" component={OrdenesListar}/>
                <ProtectedRoute exact path="/orden/crear" component={OrdenesCrear}/>
                <ProtectedRoute exact path="/orden/:estado/:id" component={OrdenDetalle}/>
                <ProtectedRoute exact path="/orden/:estado/:id/editar" component={OrdenesCrear}/>

                {/* Mantenimientos */}

                <ProtectedRoute exact path="/mantenimiento/crear" component={MantenimientosCrear} />


                {/* Reporteria */}

                <ProtectedRoute exact path="/reporte/kardex" component={Kardex_Reporte}/>
                <ProtectedRoute exact path="/reporte/mantenimientos" component ={Mantenimiento_Reporte} />
                <ProtectedRoute exact path="/reporte/instalaciones" component ={Instalacion_Reporte} />
                <ProtectedRoute exact path="/reporte/equipos" component ={Equipo_Reporte} />
                <ProtectedRoute exact path="/reporte/:orden/:estado/:id" component={OrdenDetalle}/>

                {/* Configuracion */}

                <ProtectedRoute exact path="/configuracion" component={ConfiguracionEditar}/>

                {/* Medidas */}

                <ProtectedRoute exact path="/medidas" component={MedidasListar}/>
                <ProtectedRoute exact path="/medida/crear" component={MedidasCrear}/>
                <ProtectedRoute exact path="/medida/:id/editar" component={MedidasCrear}/>

                {/* Requerimientos */}
                <ProtectedRoute exact path="/requerimientos" component={RequerimientosListar}/>
                <ProtectedRoute exact path="/requerimiento/crear" component={RequerimientosCrear}/>
                <ProtectedRoute exact path="/requerimiento/:id/editar" component={RequerimientosCrear}/>



                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
