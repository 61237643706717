import { api } from "api";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderTextArea,
    AsyncSelectField,
    renderDatePicker,
    renderDatePickerDisabled

} from "../../../Utils/renderField/renderField";

const getProcesos = (search) => {
    return api
        .get("proceso", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getPrioridad = (search) => {
    return api
        .get("prioridad", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getMaquinaria = (search) => {
    return api
        .get("maquinaria", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getUsuario = (search) => {
    return api
        .get("user", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getMantenimientoTipo = (search) => {
    return api
        .get("mantenimiento_tipo", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

let OrdenForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-12 p-0">
                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_solicitud" className="m-0">
                                Fecha creacion
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_solicitud"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_ejecucion" className="m-0">
                                Fecha inicio
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_ejecucion"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                placeholder="DD/MM/AAAA"
                                disabled
                                    
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_finalizacion" className="m-0">
                                Fecha Final
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_finalizacion"
                                component={renderDatePicker}
                                todayButton="Hoy"
                                placeholder="DD/MM/AAAA"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                
                
                <div className="row col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="proceso" className="m-0">
                                Proceso solicitante
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="proceso"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getProcesos}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="proceso_solicitado" className="m-0">
                                Proceso solicitado
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="proceso_solicitado"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getProcesos}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="maquinaria" className="m-0">
                                Maquinaria - equipo
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="maquinaria"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getMaquinaria}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    
                </div>

                <div className="row col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="prioridad" className="m-0">
                                Prioridad
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="prioridad"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPrioridad}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="requerimiento" className="m-0">
                                Requerimiento
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="requerimiento.nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                                placeholder="escriba aqui"
                                disabled
                            />
                        </div>
                    </div>

                    

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mantenimiento_tipo" className="m-0">
                                Tipo mantenimiento
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="mantenimiento_tipo"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getMantenimientoTipo}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="row col-md-12 p-0">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="encargado" className="m-0">
                                Encargado de orden
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="encargado_orden"
                                labelKey="nombre_completo"
                                label="nombre_completo"
                                component={AsyncSelectField}
                                loadOptions={getMaquinaria}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo_encargado_orden" className="m-0">
                                Código de encargado
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="codigo_encargado_orden"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="row col-md-12 p-0">
                    <div className="col-4 mt-3">
                        <label htmlFor="descripcion">Descripcion del mantenimiento</label>
                        <Field name="descripcion" component={renderTextArea} placeholder="escriba aqui" rows={4} disabled/>
                    </div>

                    <div className="col-4 mt-3">
                        <label htmlFor="observacion_adicional">Observaciones adicionales</label>
                        <Field name="observacion_adicional" component={renderTextArea} placeholder="escriba aqui" rows={4} disabled/>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    {/* <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link> */}

                    <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                        e.preventDefault();
                        props.history.goBack();
                    
                        }}>CANCELAR</button>
                    
                </div>
            </div>
        </form>
    );
};
OrdenForm = reduxForm({
    form: "OrdenForm",
})(OrdenForm);

export default OrdenForm;
