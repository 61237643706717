import { api } from "api";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    renderField,
    renderTextArea,
    AsyncSelectField,
    renderDatePicker,

} from "../../../Utils/renderField/renderField";

const renderPiezas = ({
    fields,
    meta: { error, submitFailed },
    piezas,
    submit_estado
}) => (
     <div className="col-sm-12 p-0">
        <div className="col-sm-12 form-group ">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Notas</th>
                        </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div className="tabla-con-borde">
                <table className="table table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index} disabled>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "24%",pointerEvents:"none" }}
                                    >
                                        <Field
                                            name={`${pieza}.nombre`}
                                            component={renderField}
                                            options={piezas}
                                            className="form-control"
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "16%", pointerEvents:"none"}}
                                    >
                                        <Field
                                            name={`${pieza}.cantidad`}
                                            type="number"
                                            component={renderField}
                                            decimalScale={2}
                                            parse={(val) => parseFloat(val)}

                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "60%", 
                                            pointerEvents: submit_estado?"none" : ""}}
                                    >
                                        <Field
                                            name={`${pieza}.nota`}
                                            component={renderField}

                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


let RevisionForm = (props) => {
    const { handleSubmit, permisos, editando,submit_estado } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column col-12">
                <div className="row col-md-12 d-flex p-0 ml-4">
                    <h5>Listado de piezas</h5>         
                </div>

                <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0 mt-3">
                        <div className="col-md-12 p-0">
                            <FieldArray
                                name="orden_orden_pieza"
                                component={renderPiezas}
                                submit_estado={submit_estado}
                            />
                        </div>
                    </div> 
                </div>

                <div className="col-md-12 p-0">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="notas_adicionales" className="m-0">
                            Notas adicionales
                        </label>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <Field name="observacion_nota" component={renderTextArea} placeholder="escriba aqui" rows={4} disabled={submit_estado ? submit_estado : false}/>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ordenes">
                        CANCELAR
                    </Link>
                    {editando ? (
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={permisos()}
                            style={{display: submit_estado ? "none" : ""}}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary">
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};

RevisionForm = reduxForm({
    form: "OrdenForm",
})(RevisionForm);

export default RevisionForm;
