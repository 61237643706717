import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import moment from "moment";

import validate from "./validate";

import {
    renderField,
    renderDatePicker,
} from "../../../Utils/renderField/renderField";

const renderPiezas = ({
    fields,
    meta: { error, submitFailed },
    piezas,
}) => (
     <div className="col-sm-12 p-0">
        <div className="col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index}>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "24%" }}
                                    >
                                        <Field
                                            name={`${pieza}.nombre`}
                                            component={renderField}
                                            options={piezas}
                                            className="form-control"
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "17%" }}
                                    >
                                        <Field
                                            name={`${pieza}.cantidad`}
                                            type="number"
                                            component={renderField}
                                            decimalScale={2}
                                            parse={(val) => parseFloat(val)}
                                        />
                                    </td>

                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "10%" }}
                                    >
                                        <img
                                            src={require("assets/img/icons/borrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            className="action_img"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                        />
                                    </td>
                                    {error && <li className="error">{error}</li>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-center mt-2">
                <button
                    type="button"
                    className="btn btn-outline-info py-0"
                    onClick={() => fields.push({})}
                >
                    AGREGAR PIEZAS
                </button>
            </div>
        </div>
    </div>
);

let MaquinariaForm = (props) => {
    const { handleSubmit, permisos, editando, cantidad } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div
                className="form-group grid-container d-flex align-items-center flex-column col-12 "
                style={{ paddingRight: "0", paddingLeft: "0" }}
            >
                <div
                    className="row col-md-12 p-0 mt-3"
                    style={{ paddingRight: "0", paddingLeft: "0" }}
                >
                    <div
                        className="col-6 p-0"
                        style={{ paddingRight: "0", paddingLeft: "0" }}
                    >
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">
                                Nombre
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="col-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo" className="m-0">
                                Codigo
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="codigo"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row col-md-12 p-0 mt-3">
                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_adquisicion" className="m-0">
                                Fecha de adquisicion
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha_adquisicion"
                                component={renderDatePicker}
                                todayButton="Hoy"
                            />
                        </div>
                    </div>

                    <div className="col-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="placa" className="m-0">
                                Placa (opcional)
                            </label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="placa"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    
                </div>

                <div className="row col-md-12 p-0 mt-3">
                    <div className="col-md-12">
                        <h3>Listado de piezas</h3>
                        <hr
                            style={{
                                color: "#359D9E",
                                backgroundColor: "#359D9E",
                                height: 0.5,
                                borderColor: "#359D9E",
                            }}
                        />
                    </div>
                </div>

                <div className="row col-md-12 p-0 mt-3">
                    <div className="col-md-12 p-0">
                        <FieldArray
                            name="detalle_maquinaria"
                            component={renderPiezas}
                            cantidad={cantidad}
                        />
                    </div>
                </div> 

                <div className="buttons-box mt-5">
                    <Link
                        className="btn btn-outline-dark mr-5"
                        to="/maquinarias"
                    >
                        CANCELAR
                    </Link>
                    {editando ? (
                        <button
                            type="submit"
                            className="btn btn-primary"
                            // onClick={permisos()}
                        >
                            GUARDAR
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-primary">
                            GUARDAR
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};
MaquinariaForm = reduxForm({
    form: "MaquinariaForm",
    validate,

    initialValues: {
        fecha_adquisicion: moment(),
    }
})(MaquinariaForm);



export default MaquinariaForm;
