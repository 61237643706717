import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "requerimientos",
    "requerimiento",
    "RequerimientoForm",
    "/requerimientos",
);

export default handleActions(reducers, initialState);
