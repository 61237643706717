import { api } from "api";
import React, { Fragment, useState } from "react";
import Select, { Creatable, Async } from "react-select";

import { Field } from "redux-form";

import "./mantenimientoform.css"

import {
    renderField,
    renderTextArea,
    renderFieldDisabled,
    renderDatePickerDisabled
} from "../../../Utils/renderField/renderField";

const renderMantimientos = ({
    fields,
    meta: { error, submitFailed },
    piezas,
    agregar,
    mensaje,
    handleTaskDelete,
}) => (
    <div className="col-sm-12">
        <div className="">
            <button 
                type="button" 
                className="btn btn-primary btn-agregar mt-4 mr-4"
                onClick={() => agregar(fields)}
            >
                Calendarizar
            </button>
        </div>

        <div className="row col-12 p-0">
            { mensaje && <div className="alerta error_mensaje"> {mensaje} </div> }
        </div>
        
        <div className="col-sm-12 form-group">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center" style={{ width: "25%"}}>Fecha</th>
                            <th scope="col" className="text-center" style={{ width: "25%"}}>Maquinaria</th>
                            <th scope="col" className="text-center" style={{ width: "25%"}}>Tipo de mantenimiento</th>
                            <th scope="col" className="text-center" style={{ width: "25%"}}>Acciones</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {/*Contenido de la tabla*/}

            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                        {fields.map((pieza, index) => {
                            return (
                                <tr key={index} disabled>
                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%",pointerEvents:"none" }}
                                    >
                                        <Field
                                            name={`${pieza}.fecha`}
                                            component={renderDatePickerDisabled}
                                            dateFormat="DD/MM/YYYY"
                                            width="25%"
                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%", pointerEvents:"none"}}
                                    >
                                        <Field
                                            name={`${pieza}.maquinaria`}
                                            component={renderFieldDisabled}
                                            style={{ width: "25%"}}
                                        />
                                    </td>

                                    <td
                                        className="sin-borde-top text-center"
                                        style={{ width: "25%" }}
                                    >
                                         <Field
                                            name={`${pieza}.mantenimiento_tipo`}
                                            component={renderFieldDisabled}
                                            style={{ width: "25%"}}
                                        />
                                    </td>

                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "25%" }}
                                    >
                                        <img
                                            src={require("assets/img/icons/borrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            className="action_img"
                                            onClick={() => {
                                                fields.remove(index);
                                                handleTaskDelete(index);
                                            }}
                                            
                                        />
                                    </td>
                                    
                                    <td style={{display:"none"}}>
                                   
                                        <Field
                                            name={`${pieza}.proceso`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.equipo`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.requerimiento`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.prioridad`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.encarado_orden`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.codigo_encargado_orden`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.maquinaria_id`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.descripcion`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.observacion_adicional`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />

                                        <Field
                                            name={`${pieza}.fecha_solicitud`}
                                            component={renderDatePickerDisabled}
                                            dateFormat="DD/MM/YYYY"
                                            width="25%"
                                        />

                                        <Field
                                            name={`${pieza}.fecha_final`}
                                            component={renderDatePickerDisabled}
                                            dateFormat="DD/MM/YYYY"
                                            width="25%"
                                        />

                                        <Field
                                            name={`${pieza}.proceso_solicitado`}
                                            component={renderFieldDisabled}
                                            style={{display:"none"}}
                                        />
                                    </td>

                                    
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

export default renderMantimientos;