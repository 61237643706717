import { createAction, handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import { endsWith } from 'lodash';
import RevisionForm from '../../../common/components/App/Ordenes/Crear/RevisionForm';
import PreparacionForm from '../../../common/components/App/Ordenes/Crear/PreparacionForm';

import { isNull } from "lodash";


// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_COMPRA';
const DATA = 'DATA_ORDEN';
const ITEM_DATA = 'ITEM_ORDEN';
const PAGE = 'PAGE_ORDEN';
const ORDERING = 'ORDERING_ORDEN';
const SEARCH = 'SEARCH_ORDEN';

const LOADER_GANTT = 'LOADER_GANTT';
const GANTT_DATA = 'GANTT_DATA';
const LOADER_GANTT_EXCEL = 'LOADER_GANTT_EXCEL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});




const setLoaderGantt = loaderGantt => ({
    type: LOADER_GANTT,
    loaderGantt,
});


const setLoaderGanttExcel = loaderGanttExcel => ({
    type: LOADER_GANTT_EXCEL,
    loaderGanttExcel,
});

const setGantt = gantt => ({
    type: GANTT_DATA,
    gantt,
});

// -----------------------------------
// Actions
// -----------------------------------


const leer =(id, estado=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`mantenimiento/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm("OrdenForm", response));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('mantenimiento', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(initializeForm("MantenimientoForm", ""));
        dispatch(push("/ordenes"));
    }).catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la creación', 'ERROR'); }
    else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};





const clearItem = () => dispatch => {
    dispatch(setItem({}));
    dispatch(initializeForm("MantenimientoForm", ""));
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const obtener_gantt_data = data => (dispatch) =>{
    dispatch(setGantt(data));
}

const filtroEstadoChange = estado_id => (dispatch) => {
    dispatch({type: FILTRO_ESTADO, estado_id})
}

const obtener_gantt_pdf = data => (dispatch,getStore) => {
    const resource = getStore().mantenimientos;

    dispatch(setLoaderGantt(true));
    
    api.post('orden/descargar_reporte_gantt', resource.gantt).then(response => {
        if (response === 'success') {
            const endpoint = `/api/orden/descargar_reporte_gantt_pdf/?auth_token=${localStorage.getItem("token")}`;

            // Realizar la solicitud GET al endpoint
            fetch(endpoint)
            .then(response => {
                if (!response.ok) {
                throw new Error('Error al descargar el PDF');
                }
                return response.blob();
            })
            .then(blob => {
                // Crear un objeto URL para el blob
                const url = window.URL.createObjectURL(new Blob([blob]));

                // Crear un enlace temporal
                const link = document.createElement('a');
                link.href = url;

                const fecha_descarga = moment().format('DD/MM/YYYY HH:mm');
                link.setAttribute('download', `gantt_${fecha_descarga}.pdf`);

                // Simular un clic en el enlace
                document.body.appendChild(link);
                link.click();

                // Limpiar después de la descarga
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error('Error:', error);
            });



            
        } else {
            // Maneja errores de la solicitud
            console.error('Error al realizar la solicitud:', response.statusText);
            return null;
        }
    })


   
    .catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la creación', 'ERROR'); }
        else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}




const obtener_gantt_excel = data => (dispatch,getStore) => {
    const resource = getStore().mantenimientos;
    dispatch(setLoaderGanttExcel(true));
    api.post('orden/generar_data_excel_gantt', resource.gantt).then(response => {
        
        if (response === 'success') {

            const endpoint = `/api/orden/descargar_reporte_gantt_excel/?auth_token=${localStorage.getItem("token")}`;

            // Realizar la solicitud GET al endpoint
            fetch(endpoint)
            .then(response => {
                if (!response.ok) {
                throw new Error('Error al descargar el Excel');
                }
                return response.blob();
            })
            .then(blob => {
                // Crear un objeto URL para el blob
                const url = window.URL.createObjectURL(new Blob([blob]));

                // Crear un enlace temporal
                const link = document.createElement('a');
                link.href = url;
                const fecha_descarga = moment().format('DD/MM/YYYY HH:mm');
                
                link.setAttribute('download', `gantt_${fecha_descarga}.xlsx`);

                // Simular un clic en el enlace
                document.body.appendChild(link);
                link.click();

                // Limpiar después de la descarga
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error('Error:', error);
            });



            
        } else {
            // Maneja errores de la solicitud
            console.error('Error al realizar la solicitud:', response.statusText);
            return null;
        }
    })

   
   
    .catch((e) => {
        if (isNull(e)){ NotificationManager.error('Error en la creación', 'ERROR'); }
        else{ NotificationManager.error(e.detail, 'ERROR') }
    }).finally(() => {
        dispatch(setLoader(false));
    });

    let endpoint = `/api/orden/descargar_reporte_gantt/?auth_token=${localStorage.getItem("token")}&data=${[resource.gantt]}`;
    // window.location.replace(endpoint);
}

export const actions = {
    leer,
    crear,
    searchChange,
    onSortChange,
    clearItem,
    obtener_gantt_pdf,
    obtener_gantt_data, 
    obtener_gantt_excel,
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },

    [LOADER_GANTT]: (state, { loader_gantt }) => {
        return {
            ...state,
            loader_gantt,
        };
    },

    [GANTT_DATA]: (state, { gantt }) => {
        return {
            ...state,
            gantt,
        };
    },

    [LOADER_GANTT_EXCEL]: (state, { loader_gantt_excel }) => {
        return {
            ...state,
            loader_gantt_excel,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    fecha_inicio: null,
    fecha_final: null,
    proceso: -1,
    mantenimiento_tipo: -1,
    gantt: {}
};

export default handleActions(reducers, initialState)


