import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { Link } from 'react-router-dom';
import './login.css';


const LoginForm = props => {
    const { handleSubmit } = props;
    return (
        <form name="loginForm" id="loginForm" className="form-validate d-flex flex-column flex-sm-row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-12">
                <div className="form-group has-feedback">
                    <div className='login-label'>
                        <label htmlFor="username" className="">Usuario</label>
                    </div>

                    <Field
                        name="username"
                        label="Usuario"
                        component={renderField}
                        type="text"
                        className="form-control"
                        style={{
                            border: '1px solid white',
                            color: 'white',
                        }}
                    />
                </div>
                <div className="form-group has-feedback">
                    <div className='login-label'>
                        <label htmlFor="password" className="">Contraseña</label>
                    </div>
                    
                    <Field
                        name="password"
                        label="Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                        style={{
                            border: '1px solid black',
                            color: 'white',
                        }}
                    />
                </div>
                <div className="buttons-box mt-5">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <button type="submit" className="btn btn-outline-login mb-3">Iniciar sesión</button>
                        <span className="text-center">¿Olvidaste tu contraseña?&nbsp;</span>
                        <Link to="/recuperar_pass" style={{color: 'white'}}>RECUPERAR</Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'login',
    validate: data => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    }
})(LoginForm);