import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/reportes/InstalacionR/instalacionR";
import {OrdenActions} from "../../../Utils/Grid/OrdenActions"
import moment from "moment";

const formatearFecha = (fecha, args) => {
    if (fecha === null)
        return "---"

    return moment.utc(fecha).format("DD/MM/YYYY");
};

const estado_update = (estado) =>{
    switch (estado) {
        case 1:
            return "pendiente"
        case 2:
            return "revision";
        case 3:
            return "preparacion";
        case 4:
            return "progreso";
        case 5:
            return "validacion";
        case 6:
        return "finalizado";
      default:
        return estado;
    }
}


export const TablaInstalacion = (props) => {
    const { page, data, generarReporte, user, setTabEdit } = props;

    return (
        <div>
            <Grid
                hover
                striped
                data={data}
                className="pb-4 pt-3"
                page={page}
                pagination={true}
                onPageChange={generarReporte}
            >
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    
                >
                    ID
                </TableHeaderColumn>

                <TableHeaderColumn 
                    dataField="creado"
                    dataFormat={formatearFecha}
                >
                    Fecha de registro
                </TableHeaderColumn>
                
                <TableHeaderColumn 
                    dataField="estado"
                    dataFormat={estado_update}
                    
                >
                    Estado
                </TableHeaderColumn>
                <TableHeaderColumn 
                    dataField="fecha_validacion"
                    dataFormat={formatearFecha}
                >
                    Fecha de validación
                </TableHeaderColumn>
                
                <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={OrdenActions({ ver:"orden", user, setTabEdit})}
                    >
                        Acciones
                    </TableHeaderColumn>
            </Grid>
        </div>
        
    );
};

const mapStateToProps = (state) => ({
    ...state.instalacionR,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaInstalacion);
