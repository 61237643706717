import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/maquinarias/maquinarias';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import MaquinariasCrear from './MaquinariasCrear';

const mstp = state => {
    return {...state.maquinarias}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(MaquinariasCrear)
